import { useEffect, useRef, useState } from 'react';
import ArrowPathIcon from '@heroicons/react/24/outline/ArrowPathIcon';
import { format, isAfter, isBefore, eachDayOfInterval, startOfMonth, endOfMonth, addMonths, subMonths, isSameDay, startOfWeek } from 'date-fns';
import { Tab } from '../tab/tab'; // Assuming your Tab component is reusable.

interface Props {
  currentTotal: number;
  total: number;
  take?: number;
  defaultSkip?: number;
  loading?: boolean;
  onDateChange: (take: number, skip: number, startDate: string, endDate: string) => void;
  isSeachBox?: boolean;
  isRangeTab?: boolean;
  onSearchChange?: (searchTerm: string) => void;
}

export function PaginationBar({ currentTotal, total, take = 10, defaultSkip = 0, loading = false, onDateChange, isSeachBox, isRangeTab = true, onSearchChange }: Props) {
  const [skip, setSkip] = useState(defaultSkip);
  const [rangeType, setRangeType] = useState<string>('This Month'); // Default to 'This Month'
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const today = new Date();
  const augustFirst = new Date(2024, 7, 1); // August 1, 2024

  const tabItems = ['All', 'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Custom'];

  const isDisabled = (date: Date) => {
    return isBefore(date, augustFirst) || isAfter(date, today);
  };

  const renderCalendar = (monthOffset: number) => {
    const firstDayOfMonth = startOfMonth(addMonths(currentMonth, monthOffset));
    const lastDayOfMonth = endOfMonth(addMonths(currentMonth, monthOffset));

    const days = eachDayOfInterval({
      start: firstDayOfMonth,
      end: lastDayOfMonth,
    });

    return (
      <div className="grid grid-cols-7 gap-1 p-2 bg-white rounded-lg calendar">
        {days.map((day, index) => {
          const formattedDay = format(day, 'yyyy-MM-dd');

          const endDateFormatted = customDateRange.end
            ? format(new Date(customDateRange.end), 'yyyy-MM-dd')
            : '';

          const isStartDay = formattedDay === customDateRange.start;
          const isEndDay = formattedDay === endDateFormatted;
          const isInRange = customDateRange.start && customDateRange.end &&
            new Date(day) >= new Date(customDateRange.start) &&
            new Date(day) <= new Date(customDateRange.end);

          return (
            <div
              key={index}
              className={`p-2 text-center cursor-pointer rounded-lg
                          ${isDisabled(day) ? 'text-gray-400 cursor-not-allowed' : ''}
                          ${isInRange ? 'bg-pn-blue opacity-70 text-white' : ''}
                          ${isStartDay || isEndDay ? 'bg-pn-blue !opacity-100 text-white font-bold' : ''}
                          `}
              onClick={() => {
                if (isDisabled(day)) return;

                setCustomDateRange((prev) => {
                  const newDate = formattedDay;

                  if (prev.start && !prev.end) {
                    if (new Date(newDate) < new Date(prev.start)) {
                      // If the selected end date is earlier than the start date, swap them
                      return { start: newDate, end: '' };
                    } else {
                      // If the end date is today, set it to the current time (i.e. now)
                      if (isSameDay(new Date(), day)) {
                        const now = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                        return { ...prev, end: now };
                      }

                      return { ...prev, end: newDate };
                    }
                  } else {
                    // Select a new start date, clear the end date
                    return { start: newDate, end: '' };
                  }
                });
              }}
            >
              {format(day, 'd')}
            </div>
          );
        })
        }
      </div >
    );
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const updateDateRange = () => {
    const todayClone = new Date(today);
    const startDate = new Date(todayClone);
    const endDate = new Date(todayClone);

    switch (rangeType) {
      case 'All':
        // Setting a wide date range to include all data
        startDate.setFullYear(1900, 0, 1); // January 1, 1900
        endDate.setFullYear(2300, 11, 31); // December 31, 2300
        break;
      case 'Today':
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Yesterday':
        startDate.setDate(todayClone.getDate() - 1);
        endDate.setDate(todayClone.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'This Week':
        startDate.setDate(startOfWeek(todayClone, { weekStartsOn: 1 }).getDate());
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(todayClone.getDate());
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Week':
        const lastWeekStart = new Date(todayClone);
        lastWeekStart.setDate(startOfWeek(todayClone, { weekStartsOn: 1 }).getDate() - 7);
        startDate.setTime(lastWeekStart.getTime());
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(startOfWeek(todayClone, { weekStartsOn: 1 }).getDate() - 1);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'This Month':
        startDate.setDate(1);
        endDate.setMonth(todayClone.getMonth() + 1);
        endDate.setDate(0); // Last day of the month
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Month':
        startDate.setMonth(todayClone.getMonth() - 1);
        startDate.setDate(1);
        endDate.setMonth(todayClone.getMonth());
        endDate.setDate(0); // Last day of the previous month
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'This Year':
        startDate.setMonth(0);
        startDate.setDate(1);
        endDate.setFullYear(todayClone.getFullYear() + 1);
        endDate.setMonth(0);
        endDate.setDate(0); // Last day of the year
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Custom':
        setShowCustomModal(!showCustomModal);
        return;
      default:
        break;
    }

    const formattedStartDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd HH:mm:ss');

    setCurrentPage(1);  // Reset page to 1
    setSkip(0);  // Reset skip to 0 to show the first page
    setDateRange({ start: formattedStartDate, end: formattedEndDate });
    onDateChange(take, 0, formattedStartDate, formattedEndDate);  // Pass 0 as skip value

    if (rangeType !== "Custom") {
      setShowCustomModal(false)
      setCustomDateRange({ start: '', end: '' });
    }
  };

  useEffect(() => {
    updateDateRange();
  }, [rangeType]);

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      onDateChange(take, skip, dateRange.start, dateRange.end);
    }
  }, [currentPage, skip, take, dateRange]);

  const handleCustomDateSubmit = () => {
    const { start, end } = customDateRange;

    if (!isAfter(new Date(start), augustFirst) || !isBefore(new Date(end), today)) {
      alert('Select dates between August 1, 2024, and today.');
      return;
    }

    setDateRange({ start, end });
    onDateChange(take, skip, start, end);
    setShowCustomModal(false);
  };

  const handleNextPage = () => {
    setSkip(skip + take);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (skip > 0) {
      setSkip(skip - take);
      setCurrentPage(currentPage - 1);
    }
  };

  const refresh = () => {
    setIsRefreshing(true);
    onDateChange(take, skip, dateRange.start, dateRange.end);
    setTimeout(() => setIsRefreshing(false), 500);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(newSearchTerm);
      }
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="date-filter">
        <div className="flex flex-col items-center justify-between md:flex-row gap-2">
          {/* Tab component for date options */}
          {isRangeTab && (
            <Tab
              items={tabItems}
              selectedTabItem={rangeType}
              loading={false}
              handleTabItemClick={setRangeType}
            />
          )}

          {isSeachBox && (
            <input
              type="search"
              placeholder="Search Investors..."
              value={searchTerm}
              onChange={handleSearchChange}
              className='rounded-3xl py-2.5 px-3 border border-gray-300 mb-4 w-full outline-none lg:w-1/4'
            />
          )}
        </div>

        {/* Custom Date Range Modal */}
        {showCustomModal && (
          <div className="flex items-center justify-center p-6 bg-black bg-opacity-50">
            <div className="w-full max-w-3xl p-4 bg-white rounded-lg shadow-md modal-content">
              <h3 className="mb-4 text-lg font-semibold">Select Custom Date Range</h3>

              <div className="flex items-center justify-between mb-4">
                <button className="px-4 py-2 text-white rounded-lg bg-pn-blue" onClick={handlePrevMonth}>
                  Prev
                </button>
                <button className="px-4 py-2 text-white rounded-lg bg-pn-blue" onClick={handleNextMonth}>
                  Next
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4">
                {/* Render current month and the previous month calendars */}
                <div>
                  <h4 className="mb-2 font-semibold text-center">
                    {format(subMonths(currentMonth, 1), 'MMMM yyyy')}
                  </h4>
                  {renderCalendar(-1)} {/* Last month's calendar */}
                </div>
                <div>
                  <h4 className="mb-2 font-semibold text-center">
                    {format(currentMonth, 'MMMM yyyy')}
                  </h4>
                  {renderCalendar(0)} {/* This month's calendar */}
                </div>
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <button
                  className="px-4 py-2 text-white rounded-lg bg-pn-blue"
                  onClick={handleCustomDateSubmit}
                  disabled={!customDateRange.start || !customDateRange.end}
                >
                  Apply
                </button>
                <button className="px-4 py-2 text-white bg-gray-500 rounded-lg" onClick={() => setShowCustomModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Display the selected date range */}
        {customDateRange.start && customDateRange.end && (
          <div className="mt-4">
            <p>
              Selected Range:
              <span className="font-semibold">
                {format(new Date(customDateRange.start || today), 'MMMM d, yyyy')}
              </span>
              {' '}to{' '}
              <span className="font-semibold">
                {format(new Date(customDateRange.end || today), 'MMMM d, yyyy')}
              </span>
            </p>
          </div>
        )}

      </div>
      <div className="flex flex-col items-center justify-between mb-2 sm:flex-row">
        {/* Page number display */}
        <div className="mb-2 text-center sm:mb-0 sm:text-left">
          Page {currentPage} of {Math.ceil(total / take)} pages
        </div>

        {/* Records count display */}
        <div className="mb-2 text-center sm:mb-0">
          {(() => {
            const start = skip + 1;
            const end = Math.min(skip + take, total);
            return `Showing ${start}-${end} of ${total} records`;
          })()}
        </div>

        {/* Pagination and Refresh buttons */}
        <div className="flex justify-center sm:justify-end">
          <button
            onClick={() => {
              setIsRefreshing(true);
              refresh();
              setTimeout(() => {
                setIsRefreshing(false);
              }, 500);
            }}
            className="p-2 mr-2 sm:mr-4 text-white rounded-lg bg-[#14223D] hover:bg-[#1d325e] disabled:bg-slate-400"
            disabled={isRefreshing}
          >
            <ArrowPathIcon className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
          </button>

          <button
            onClick={handlePrevPage}
            disabled={skip === 0}
            className={`rounded-lg mr-2 sm:mr-4 text-white py-2 px-4 ${skip !== 0 ? 'bg-[#14223D] hover:bg-[#1d325e]' : 'bg-slate-400'
              }`}
          >
            Previous
          </button>

          <button
            onClick={handleNextPage}
            disabled={currentTotal < take || skip + take >= total}
            className={`rounded-lg text-white py-2 px-4 ${currentTotal >= take && skip + take < total ?
              'bg-[#14223D] hover:bg-[#1d325e]' : 'bg-slate-400'
              }`}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
