import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import { useState } from 'react';
import { Transaction, TransactionStatus, User } from '../../../__generated__/graphql';
import { LoadingPlaceholder } from '../../../components/loading/placeholder';
import { PaginatedData } from '../../../interfaces/pagination.interface';
import { toDateTimeForDisplay } from '../../../utils//string.utils';
import { toCurrencyDisplay } from '../../../utils/currency.util';
import { formatRelative } from 'date-fns';
import { FormButton } from '../../../components/form/button';
import { Modal } from '../../../components/modal/modal';
import { useMutation } from '@apollo/client';
import { MUTATION_REFUND_TRANSACTION } from '../../../graphql/mutations/transaction';
import { useNavigate } from 'react-router-dom';
import { FormSelect } from '../../../components/form/select';

interface Props {
  data: PaginatedData<Transaction>,
  loading: boolean;
  refresh: () => void;
}

export function TransactionDepositTable({ data, loading, refresh }: Props) {
  const navigate = useNavigate();
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [refundTransaction, { loading: refundLoading }] = useMutation(MUTATION_REFUND_TRANSACTION);
  const [refundResult, setRefundResult] = useState<{ success: boolean; message: string } | null>(null);
  const [refundReason, setRefundReason] = useState<string>('requested_by_customer');

  if (loading) {
    return <LoadingPlaceholder />;
  }

  function getFullName(user: User) {
    return [user.firstName, user.lastName].join(' ');
  }

  function handleRefund(transaction: Transaction, event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedTransaction(transaction);
  }

  async function confirmRefund() {
    if (!selectedTransaction) return;

    try {
      const { data } = await refundTransaction({
        variables: {
          transactionId: selectedTransaction.id,
          reason: refundReason
        },
      });

      if (data.refundTransaction) {
        setRefundResult({
          success: true,
          message: `Transaction ${selectedTransaction.code} has been refunded successfully.`
        });
        refresh();
      } else {
        setRefundResult({
          success: false,
          message: 'Refund was not successful. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error refunding transaction:', error);
      setRefundResult({
        success: false,
        message: 'Failed to refund transaction. Please try again.'
      });
    }
  }

  function closeModal() {
    setSelectedTransaction(null);
    setRefundResult(null);
  }

  const handleRefundReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRefundReason(e.target.value);
  };

  return (
    <>
      <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
        <thead>
          <tr>
            <th className='px-4 py-3 border-b'>TX Code</th>
            <th className='px-4 py-3 border-b'>User</th>
            <th className='px-4 py-3 border-b'>Amount</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>CC Fee</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>Stripe Fee</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>User Status</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>Transaction Status</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>Balance</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>Time</th>
            <th className='hidden px-4 py-3 border-b md:table-cell'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {!loading && data && data.items && data.items.map((transaction: Transaction, index: number) => (
            <tr
              key={index}
              className='transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white'
              onClick={() => navigate(`/investor/${transaction.user.id}`)}
            >
              <td className='px-4 py-3 text-sm text-nowrap'>{transaction.code}</td>
              <td className='px-4 py-3 text-sm text-nowrap'>
                <b>{getFullName(transaction.user)}</b>
                <br />{transaction.user.email}
                <br />{transaction.user.phone}
              </td>
              <td className='px-4 py-3 text-sm'>{toCurrencyDisplay(transaction.amount)}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>{toCurrencyDisplay(transaction.ccFee, true, "£", 100, 2)}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>{toCurrencyDisplay(transaction.stripeFee, true, "£", 100, 2)}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>{transaction.user.status}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>{transaction.status}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>{toCurrencyDisplay(transaction.user.investorProfile?.balance)}</td>
              <td className='hidden px-4 py-3 text-sm text-center md:table-cell' title={toDateTimeForDisplay(transaction.finalisedAt)}>{formatRelative(transaction.finalisedAt, new Date())}</td>
              <td className='hidden px-4 py-3 text-sm md:table-cell'>
                <FormButton
                  title='Refund'
                  size='xs'
                  onClick={(event) => handleRefund(transaction, event as React.MouseEvent<HTMLButtonElement>)}
                  disabled={transaction.status === TransactionStatus.Refunded}
                />
              </td>
            </tr>
          ))}
          {!loading && (!data || !data.items || data.items.length === 0) && <tr><td colSpan={8} className='text-sm text-center'>
            <div className='flex flex-col items-center gap-2 p-5'>
              <InformationCircleIcon width={60} />
              <p className='font-bold'>No Deposits</p>
            </div>
          </td></tr>}
        </tbody>
      </table>

      {selectedTransaction && (
        <Modal title={refundResult ? "Refund Result" : "Confirm Refund"} onClose={closeModal}>
          {!refundResult ? (
            <>
              <p>Are you sure you want to refund the transaction {selectedTransaction.code}?</p>
              <p>Amount: {toCurrencyDisplay(selectedTransaction.amount)}</p>
              <p>User: {getFullName(selectedTransaction.user)}</p>
              <div className="mt-4">
                <FormSelect
                  label="Refund Reason"
                  onChange={handleRefundReasonChange}
                  options={[
                    { value: 'requested_by_customer', label: 'Requested by Customer' },
                    { value: 'duplicate', label: 'Duplicate' },
                    { value: 'fraudulent', label: 'Fraudulent' },
                  ]}
                />
              </div>
              <div className="flex justify-end mt-4 space-x-2">
                <FormButton title="Cancel" onClick={closeModal} color='red' />
                <FormButton
                  title="Confirm Refund"
                  onClick={confirmRefund}
                  loading={refundLoading}
                  disabled={refundLoading}
                />
              </div>
            </>
          ) : (
            <>
              <p className={refundResult.success ? "text-green-600" : "text-red-600"}>
                {refundResult.message}
              </p>
              <div className="flex justify-end mt-4">
                <FormButton title="Close" onClick={closeModal} />
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
