import { LoadingSpinner } from "../loading/spinner";

interface Props {
  items: string[];
  selectedTabItem: string;
  loading: boolean;
  handleTabItemClick: (tabItem: string) => void;
}

export function Tab({ items, selectedTabItem, loading, handleTabItemClick }: Props) {

  function getClassNameForTabItem(tabItem: string) {
    let className = 'flex flex-row px-4 py-2 text-sm font-semibold cursor-pointer rounded-3xl hover:bg-pn-blue hover:text-white ';
    className += 'transition-all duration-300 self-center';
    let selectedClassName = 'bg-pn-light-yellow';
    return selectedTabItem === tabItem ? `${className} ${selectedClassName}` : className;
  }

  return (
    <div className='group flex flex-col md:flex-row md:max-w-fit rounded-3xl gap-2 bg-white py-4 px-2 md:!py-1 md:px-2 mb-4 shadow transition-all duration-300'>
      {items.map((item, index) => {
        return <div key={index} className={getClassNameForTabItem(item)} onClick={() => { handleTabItemClick(item) }}>
          {loading && selectedTabItem === item && <LoadingSpinner className='w-5 h-5 mr-2 text-pn-blue group-hover:text-white' />}
          {item}
        </div>
      })}
    </div>
  );
}