import { HTMLProps, useEffect, useState } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLSelectElement> {
  label: string;
  options: { value: string, label: string }[];
  containerClassname?: string;
  isCurrency?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function FormSelect({ label, options, containerClassname, className, isCurrency, onChange, ...props }: Props) {

  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    setValue(props.defaultValue || '')
  }, [props.defaultValue])

  function getInputClassname() {
    let className = 'w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 '
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 '

    if (className) {
      className += className;
    }
    return className;
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className="mb-2 text-sm font-semibold" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute text-sm top-3 text-slate-500 right-5">{isCurrency ? toHumanReadableCurrency(parseInt(value as string) * 100) : ''}</p>
        <select 
          className={getInputClassname()} 
          id={getId()} 
          {...props} 
          onChange={(e) => { 
            setValue(e.currentTarget.value); 
            if (onChange) {
              onChange(e);
            }
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
}