import { NavigateFunction } from "react-router-dom";
import { apolloClient } from "..";
import {
  RoleAction,
  RoleAssignment,
  RoleModule,
} from "../__generated__/graphql";
import { QUERY_MY_ROLE } from "../graphql/queries/user";
import { AppRoute } from "../routes";
const { request, gql } = require("graphql-request");

class _AuthService {
  private sKeyEmail = "email";
  private sKeyToken = "token";
  private sKeyRole = "role";
  private skeydeviceId = "deviceId";

  async login(email: string, accessToken: string, deviceId: string) {
    localStorage.setItem(this.sKeyEmail, email);
    localStorage.setItem(this.sKeyToken, accessToken);
    localStorage.setItem(this.skeydeviceId, deviceId);

    await this.refreshRoles();
  }

  async logout(navigate: NavigateFunction) {
    localStorage.clear();
    navigate(AppRoute.AccountLogin);
  }

  async authenticate(navigate: NavigateFunction) {
    const email = localStorage.getItem(this.sKeyEmail);

    if (!email) {
      this.logout(navigate);
      return false;
    }

    const deviceId = localStorage.getItem(this.skeydeviceId);
    const token = localStorage.getItem(this.sKeyToken);

    // Check if token is missing or empty (even after trimming)
    if (!token || token.trim() === "") {
      this.logout(navigate);
      return false;
    }

    const query = gql`
      query verifyDevice($email: String!, $deviceId: String!) {
        verifyDevice(email: $email, deviceId: $deviceId)
      }
    `;
    //console.log("api url" , process.env.REACT_APP_API_URL) ;
    const authenticated = await request(
      process.env.REACT_APP_API_URL! + "/admin",
      query,
      { email, deviceId },
      {
        Authorization: `Bearer ${token?.trim()}`,
      }
    );

    if (!authenticated.verifyDevice) {
      navigate(AppRoute.LoginVefitication);
      return false;
    }

    await this.refreshRoles();
    return true;
  }

  getToken() {
    return localStorage.getItem(this.sKeyToken);
  }

  async refreshRoles() {
    const response = await apolloClient.query({
      query: QUERY_MY_ROLE,
      fetchPolicy: "no-cache",
    });
    localStorage.setItem(this.sKeyRole, JSON.stringify(response.data.myRole));
  }

  hasRole(module: RoleModule, action: RoleAction) {
    const roleString = localStorage.getItem(this.sKeyRole);
    if (!roleString) return false;

    try {
      const role = JSON.parse(roleString);
      if (!role || !role.assignments) return false;

      return role.assignments.some(
        (assignment: RoleAssignment) => 
          assignment.module === module && assignment.action === action
      );
    } catch (error) {
      console.error("Error parsing role from localStorage:", error);
      return false;
    }
  }

  getFirstAccessibleRoute(): AppRoute | null {
    const routes = [
      { route: AppRoute.Analytics, module: RoleModule.Analytics, action: RoleAction.View },
      { route: AppRoute.PropertyList, module: RoleModule.Property, action: RoleAction.List },
      { route: AppRoute.Cities, module: RoleModule.Cities, action: RoleAction.List },
      { route: AppRoute.Investors, module: RoleModule.Investor, action: RoleAction.List },
      { route: AppRoute.Transactions, module: RoleModule.Transaction, action: RoleAction.List },
      { route: AppRoute.Rewards, module: RoleModule.Rewards, action: RoleAction.List },
      { route: AppRoute.AdminList, module: RoleModule.Admin, action: RoleAction.List },
      { route: AppRoute.LeadList, module: RoleModule.Leads, action: RoleAction.List },
      { route: AppRoute.RoleList, module: RoleModule.Role, action: RoleAction.List },
      { route: AppRoute.Cms, module: RoleModule.Cms, action: RoleAction.Update },
    ];

    for (const { route, module, action } of routes) {
      if (this.hasRole(module, action)) {
        return route;
      }
    }

    return null;
  }
}

export const AuthService = new _AuthService();
