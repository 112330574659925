import React from 'react';
import { Navigate } from 'react-router-dom';
import { RoleAction, RoleModule } from '../../__generated__/graphql';
import { AppRoute } from '../../routes';
import { AuthService } from '../../services/auth.service';

interface ProtectedRouteProps {
    module: RoleModule;
    actions: RoleAction[]; // Change this to an array of actions
    element: React.ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ module, actions, element }) => {
    // Check if the user has any of the required actions
    const hasPermission = actions.some(action => AuthService.hasRole(module, action));

    if (hasPermission) {
        return element;
    } else {
        // Redirect to the first accessible route or a default route
        const accessibleRoute = AuthService.getFirstAccessibleRoute();
        return <Navigate to={accessibleRoute || AppRoute.AccountLogin} replace />;
    }
};
