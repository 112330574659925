import { LoadingSpinner } from "../loading/spinner";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  title: string;
  loading?: boolean;
  icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>>
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: 'blue' | 'yellow' | 'white' | 'red';
  disabled?: boolean;
}

export function FormButton({ title, loading, icon: Icon, disabled, ...buttonProps }: Props) {
  const size = buttonProps.size || 'md';
  const color = buttonProps.color || 'blue';
  const type = buttonProps.type || 'button';

  function getSize() {
    switch (size) {
      case 'xs':
        return 'h-6 px-2 py-1';
      case 'sm':
        return 'h-8 px-2 py-1';
      case 'md':
        return 'h-10 px-4 py-2';
      case 'lg':
        return 'h-12 px-6 py-3';
      case 'xl':
        return 'h-14 px-8 py-4';
    }
  }

  function getTextSize() {
    switch (size) {
      case 'xs':
        return 'text-xs';
      case 'sm':
        return 'text-sm';
      case 'md':
        return 'text-base';
      case 'lg':
        return 'text-lg';
      case 'xl':
        return 'text-xl';
    }
  }

  function getPadding() {
    switch (size) {
      case 'xs':
        return 'px-2 py-1';
      case 'sm':
        return 'px-3 py-1';
      case 'md':
        return 'px-4 py-2';
      case 'lg':
        return 'px-8 py-3';
      case 'xl':
        return 'px-8 py-4';
    }
  }

  function getColor() {
    switch (color) {
      case 'blue':
        return 'bg-pn-blue hover:bg-pn-dark-yellow';
      case 'yellow':
        return 'bg-pn-dark-yellow hover:bg-pn-blue';
      case 'white':
        return 'border-2 border-slate-300 bg-white hover:bg-gray-200';
      case 'red':
        return 'bg-red-500 hover:bg-red-600';
    }
  }

  function getTextColor() {
    switch (color) {
      case 'blue':
        return 'text-white';
      case 'yellow':
        return 'text-white';
      case 'white':
        return 'text-pn-blue';
      case 'red':
        return 'text-white';
    }
  }

  return (
    <button className={`group flex flex-wrap justify-center mb:w-auto gap-2 rounded-lg si content-center transition-all duration-300  disabled:bg-slate-400 ${getSize()} ${getTextSize()} ${getPadding()} ${getColor()}  `} { ...buttonProps } type={type} disabled={disabled}>
      {Icon && <Icon width={24} className='text-white' />}
      {loading && <LoadingSpinner className='w-5 h-5 mr-2' />}
      <span className={`font-semibold text-sm self-center ${getTextColor()}`}>{title}</span>
    </button>
  )
}